<template lang="">
  <div>
    <b-card>
      <basetable
        :dataurl="dataurl"
        :fields="fields"
        :baseroute="baseroute"
        :title="title"
      />
    </b-card>
  </div>
</template>
<script>
import basetable from '@/views/base/BaseTableV3.vue'
import {BCard} from 'bootstrap-vue'

export default {
  components: {
    basetable,
    BCard
  },
  data() {
    return {
      title:'Daftar Area',
      dataurl:'/service-area',
      baseroute:'area',
      fields:[
        { key: 'region.name', label: 'Region', sortable: true},
        { key: 'code', label: 'Kode', sortable: true},
        { key: 'name', label: 'Area Layanan'},
        { key: 'address', label: 'Alamat', sortable: true},
        { key: 'leader.name', label: 'Pimpinan'},
        { key: 'pic.name', label: 'PIC Operasional'},
      ],
    }
  },
}
</script>
<style lang="">

</style>
